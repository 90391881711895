<template>
    <div class="black-container">
        <div class="container is-fluid about-us">
            <b-breadcrumb size="is-small">
                <b-breadcrumb-item tag='router-link' to="/">Kovářství Buchar</b-breadcrumb-item>
                <b-breadcrumb-item tag='router-link' to="/o-nas" active>{{ $ml.get('GALERIE') }}</b-breadcrumb-item>
            </b-breadcrumb>

            <section class="content">

                <h1>{{ $ml.get('GALERIE') }}</h1>
                <vue-masonry-wall :items="gallery"  :options="{ width: 300, padding: {
                        2: 8,
                        default: 0
                    } }">
                  <template v-slot:default="{ item }">
                    <div  @click="current_image = item.src; modal_active = true" class="clickable">
                    <b-image :src="item.src"></b-image></div>
                  </template>
                </vue-masonry-wall>
                
            </section>
        </div>
        <b-modal v-model="modal_active">
            <p class="image">
                <img :src="current_image">
            </p>
        </b-modal>
    </div>
</template>
<style scoped lang="less">
    
</style>
<script>
    //import axios from "axios";

    export default {
        name: 'veShopTopbar',
        title: "Kovářství Buchar",
        components: {
        },
        data() {
            return {
                current_image: 'https://kovarstvibuchar.cz/assets/img/gallery/gallery_1.jpg',
                modal_active: false,
                gallery: [
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_1.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_2.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_3.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_4.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_5.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_6.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_7.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_8.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_9.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_10.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_11.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_12.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_13.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_14.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_15.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_16.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_17.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_18.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_19.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_20.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_21.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_22.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_23.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_24.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_25.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_26.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_27.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_28.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_29.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_30.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_31.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_32.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_33.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_34.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_35.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_36.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_37.jpg'},
                    {src:'https://kovarstvibuchar.cz/assets/img/gallery/gallery_38.jpg'},
                ]
            }
        },
        mounted() {
            this.$Progress.finish();
        }


    }
</script>