import Vue from 'vue'
import App from './App.vue'
import titleMixin from './titleMixin'
import VueRouter from 'vue-router'
import './assets/custom.scss'
import VueProgressBar from 'vue-progressbar'
import Buefy from 'buefy'
import '@mdi/font/css/materialdesignicons.css'
import VueMeta from 'vue-meta'
import vuescroll from 'vuescroll';
import './ml'
import PortalVue from 'portal-vue'
Vue.use(PortalVue)

    import VueMasonryWall from 'vue-masonry-wall'
import VueGtag from "vue-gtag";
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
Vue.use(VueGtag, {
  config: { id: "G-6Y5PQD81B8" }
});

Vue.use(VueMasonryWall)


Vue.use(Buefy, {
    customIconPacks: {
        'mdi': {
            sizes: {
                'default': 'mdi-20px',
                'is-small': 'mdi-20px',
                'is-medium': 'mdi-20px',
                'is-large': 'mdi-24px'
            }
        }
    }
})
Vue.use(vuescroll, {
 ops: {
          vuescroll: {
            
          },
          scrollPanel: {
          },
          rail: {},
          bar: {
            showDelay: 500,
    onlyShowBarOnScroll: true,
    keepShow: false,
    background: '#c1c1c1',
    opacity: 1,
    hoverStyle: false,
    specifyBorderRadius: false,
    minSize: 0,
    size: '6px',
    disable: false
          }
        },
  name: 'myScroll' // customize component name, default -> vueScroll
});
Vue.use(VueRouter)
Vue.mixin(titleMixin)
import './assets/main.css'
import router from './router'
Vue.config.productionTip = false
Vue.use(VueProgressBar, {
  color: '#8A0000',
  failedColor: 'red',
  height: '15px'
})
Vue.use(VueMeta);

import VueCookies from 'vue-cookies'

Vue.use(VueCookies, { expires: '7d'})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
