<template>
  <div id="app" class="veshop-wrapper" :class="{
    'show-background': $route.name != 'Podkategorie' && $route.name != 'Kategorie'
  }">
    <div class="veshop-header">
      <Topbar :cart="cart" />
    </div>
    <div class="veshop-client">
      <vue-progress-bar></vue-progress-bar>
      <transition :name="transitionName" mode="out-in">
        <router-view />
      </transition>
      <Footer></Footer>
      <div :class="[this.expanded ? 'expanded' : '','sidebar-component is-hidden']">
        <Sidebar />
      </div>
      <div :class="[this.expanded ? 'expanded' : '','main-componen']" id="main">
        <div class="content-subcomponent">
          
        </div>
      </div>
    </div>
    <div class="veshop-background">
      <portal-target name="destination"></portal-target>
    </div>

    <!--<vue-cookie-accept-decline :debug="false" :disableDecline="false" :showPostponeButton="false" @status="cookieStatus"
      elementId="myPanel1" position="bottom-left" ref="myPanel1" transitionName="slideFromBottom" type="floating">
      <template #postponeContent>&times;</template>

      <template #message>
        Tento web používá soubory cookies, aby se zajistilo, že získáte nejlepší možnou zkušenost při používání našeho
        webu.
        <a href="https://cookiesandyou.com/" target="_blank">Zjistěte více...</a>
      </template>

      <template #declineContent>Ne, díky</template>

      <template #acceptContent>Jasný!</template>
    </vue-cookie-accept-decline>-->
  </div>
</template>
<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,300;12..96,400;12..96,600;12..96,700&display=swap');

  @font-face {
    font-family: 'Old English CE';
    src: url('./assets/OldEnglishCE.woff2') format('woff2'),
      url('./assets/OldEnglishCE.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  html,
  body,
  #app {
    overflow: hidden;
    width: 100vw;

    background-size: cover;

    &.show-background {
      background-image: url("./assets/background.jpg");
    }
  }

  html[data-theme="dark"] {
    --surface: rgb(19, 17, 17);
  }

  html[data-theme="light"] {
    --surface: rgba(252, 227, 227, 0.13);
  }

  .content-subcomponent>* {
    overflow-x: hidden;
  }

  h1,
  h2,
  h3,
  .title {
    font-family: "Old English CE" !important;
  }

  html {
    color: white !important;
    background-color: rgb(19, 17, 17) !important;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .veshop-sidebar {
    width: 100%;
    height: 100vh;
    padding: 25px;
    display: flex;
    text-align: center;
    flex-direction: column;
    position: absolute;
    font-family: "Bricolage Grotesque",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    transition: all 0.3s ease;
    left: 0;
  }

  * {
    font-family: "Bricolage Grotesque",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;

  }

  .amita {
    font-family: 'Bricolage Grotesque',
      cursive !important;
  }

  .veshop-sidebar-spacer {
    height: 100%;
  }


  @media screen and (max-width: 768px) {
    .oswald {
      font-variant: normal !important;
      font-weight: 200 !important;
    }

    .veshop-sidebar-menu .button {
      font-variant: normal !important;
      font-weight: 200 !important;
    }
  }

  .title {
    font-family: "Bricolage Grotesque",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
  }

  .sidebar-component {
    transition: all 0.3s ease;
    width: 250px;
    position: relative;
  }

  .vsc-1 {
    font-size: 1rem;
    font-weight: lighter;
    margin-bottom: -5px;
  }

  .vsc-1 img {
    margin-right: 5px;
    margin-top: 2px;
  }

  .breadcrumb .is-active a {
    color: white !important;
  }

  .vsc-2 {
    font-size: 1.8rem;
    font-weight: bold;
  }

  .button.has-icon-custom img {
    margin-bottom: -5px;
    margin-left: -5px;
    margin-right: 5px;
    margin-top: 4px;
  }

  .button.has-icon-custom {
    font-size: 18px !important;
  }

  .divider-component {
    width: 2px;
    margin-left: 5px;
    margin-right: 5px;
    background-size: contain;
  }


  .veshop-client {
    width: calc(100% - 48px);
    max-width: calc(1500px - 48px);
  }

  .veshop-header {
    width: 100%;
    max-width: 1500px;
    height: 96px;
  }



  .zoom {
    position: absolute;
    width: 40px;
    opacity: 0;
    animation: zoom 1s linear forwards;
  }

  @keyframes zoom {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      top: 40px;
      right: 40px;
    }
  }

  .button.is-primary.is-loading::after {
    border-color: transparent transparent #fff #8A0000 !important;
  }

  .infocol-src .sr1 :focus-visible {
    border: none;
    border-bottom: #8A0000;
  }

  .infocol-src {
    padding-right: 0px;
  }

  .fillcol-src {
    padding-left: 0px;
  }

  .veshop-wrapper {
    position: relative;
  }

  .main-page {
    margin-top: 10px;
  }

  .main-page-carousel {

    height: 400px;
  }

  .category-head {
    margin-bottom: -5px !important;
  }

  .slide-left-enter-active,
  .slide-left-leave-active,
  .slide-right-enter-active,
  .slide-right-leave-active {
    transition-duration: 0.5s;
    transition-property: height, opacity, transform;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    overflow: hidden;
  }

  .slide-left-enter,
  .slide-right-leave-active {
    opacity: 0;
    transform: translate(2em, 0);
  }

  .slide-left-leave-active,
  .slide-right-enter {
    opacity: 0;
    transform: translate(-2em, 0);
  }

  .subcategory-box-mobile {
    border: 1px #dbdbdb solid;
    border-radius: 4px;
    width: 100%;
    height: 150px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    -ms-user-select: none;
    user-select: none;
    background-color: white;
    font-family: "Bricolage Grotesque",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    padding: 0px;
  }

  @media screen and (min-width: 1024px) {
    .dropdown .background {
      display: unset;
    }
  }

  .dropdown .background {
    z-index: 15;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.3);
  }

  .dropdown-content {
    background-color: black !important;
  }

  .dropdown-item:hover {
    background-color: rgb(102, 102, 102) !important;
    color: white !important;

  }

  .subcategory-box {
    border: 1px solid #8A0000;
    background-color: transparent;

    width: 200px;
    height: 150px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    -ms-user-select: none;
    user-select: none;
    font-family: "Bricolage Grotesque",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    padding: 0px;
  }

  .category-box {
    border: none;
    width: 200px;
    height: 150px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    -ms-user-select: none;
    user-select: none;
    background-color: #8A0000;
    font-family: "Bricolage Grotesque",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    padding: 0px;

    font-size: 1.5rem;
    color: black
  }

  .category-box img {
    width: 50px;
    margin-bottom: -15px;
  }

  .subcategory-box:hover,
  .subcategory-box-mobile:hover {
    border: 1px #b5b5b5 solid;
  }

  .subcategory-box:active {
    border: 1px #4a4a4a solid;
  }

  .subcategory-box:focus:not(:active) {
    box-shadow: 0 0 0 0.125em rgb(148 118 0 / 25%);
  }

  .subcategory-box:focus {
    border-color: #8A0000;
    color: #363636;
  }

  .subcategory-box .name {
    position: absolute;
    top: 10px;

    color: white;
    width: 100%;
    text-align: center;
    font-size: 24px;
  }

  .subcategory-box-mobile .name {
    position: absolute;
    top: 10px;

    color: white;
    width: 100%;
    text-align: center;
    font-size: 24px;
  }

  .subcategory-box-mobile img {
    position: absolute;
    bottom: -25%;
    right: -15%;
    max-height: 130px;
  }

  .subcategory-box img {
    position: absolute;
    bottom: -25%;
    right: -15%;
    mix-blend-mode: color-burn;
    max-height: 130px;
  }

  .subcategory-box .counter {
    position: absolute;
    color: white;
    bottom: 10px;

    left: 15px;
    text-align: left;

  }

  .subcategory-box .count {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: -5px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.3s;
    transition-property: height,
      opacity;
    overflow: hidden;
    transition-timing-function: ease;

  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }

  .field:not(:last-child) {
    margin-bottom: 0.25rem;
  }



  .brick {
    position: relative !important;

  }

  .brick.imh {
    height: 100%;
  }

  .brick.nmh {
    font-family: "Bricolage Grotesque",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    font-size: 1.5rem;
  }

  .product-most {
    border: 1px #dbdbdb solid;
    border-radius: 4px;
    width: 100%;
    height: 220px;
    padding: 15px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    -ms-user-select: none;
    user-select: none;
    background-color: white;
    transition: all 0.2s ease;

  }

  .product-most .columns {
    height: calc(100% + 25px);
  }

  .product-most .product-image {
    height: 100%;
    top: 0;
    bottom: 0;
  }


  .cart-shippingmeter {
    margin-top: 15px;
  }

  .cart-shippingmeter p {
    text-align: center;
    font-size: 1.1rem;
    margin-top: -5px;
  }

  .cart-item-image {
    height: 150px;
  }

  .cart-item-cols {
    height: 100%;
  }

  .cart-item-title {
    font-family: "Bricolage Grotesque",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    font-size: 1.5rem;
    color: white;
  }

  .veshop-background {
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -1;
    height: 100vh;
  }





  .product-image {
    position: absolute;
    width: 70%;
    left: 0;
    right: 0;
    top: 30px;
    bottom: 100px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto;
  }

  .pb-counter .count {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: -5px;
  }

  .pb-counter .countTag {
    font-size: 10px;
  }

  .pb-name-buttons {
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
  }

  .pb-name-buttons p {
    font-family: "Bricolage Grotesque",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    color: white;
    margin-bottom: 10px;
    font-size: 21px;
  }

  .pagination-link {
    border: none !important;
    color: white !important;
  }

  .pagination-link[disabled] {
    background-color: rgb(97, 0, 0) !important;
  }

  .paginate-cart .step-details,
  .paginate-cart .step-title {
    font-family: "Bricolage Grotesque",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 1.5rem !important;
    font-weight: normal !important;
  }

  .fade-in-bottom {
    -webkit-animation: fade-in-bottom 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-bottom 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }

  /* ----------------------------------------------
   * Generated by Animista on 2022-1-2 2:3:47
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info. 
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */

  /**
   * ----------------------------------------
   * animation fade-in-bottom
   * ----------------------------------------
   */
  @-webkit-keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  .veshop-client {
    height: 100%;
    position: relative;
    z-index: 100;
    margin: auto;
  }

  .sidebar-component {
    height: -webkit-fill-available;
  }

  .main-component {
    overflow: scroll;
  }

  .buttons-custom .button:not(:last-child) {
    margin-right: 10px;
  }

  .label {
    color: white !important;
  }

  ::selection {
    background: #8A0000;
    color: white;
  }

  ::-moz-selection {
    background: #8A0000;
    color: white;
  }

  .clickable {
    cursor: pointer;
  }

  .clickable:hover {
    text-decoration: underline;
  }

  .caps {}

  .pp-tags {
    margin-top: -15px
  }

  .topbar-logo-tablet,
  .topbar-button-tablet {
    height: 50px;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 20px;

  }

  .topbar-button-tablet {
    width: 0;
  }

  .topbar-button-tablet:focus:not(:active) {
    box-shadow: none;
    color: rgb(148 118 0 / 25%);
  }

  @media only screen and (max-width: 1024px) {
    .veshop-sidebar {
      transition: all 0.3s ease;
      left: -600px;
      opacity: 0;
    }

    .veshop-sidebar.expanded {
      transition: all 0.3s ease;
      left: 0;
      opacity: 1;
    }

    .divider-component {
      transition: all 0.3s ease;
      display: none;
    }

    .divider-component.expanded {
      transition: all 0.3s ease;
      width: 2px;
    }

    .sidebar-component {
      width: 0px;
      transition: all 0.3s ease;
      position: relative;
    }

    .sidebar-component.expanded {
      transition: all 0.3s ease;
      width: 250px;
    }

    .main-component {
      transition: all 0.3s ease;
      width: 100%;
    }

    .main-component.expanded {
      left: 250px;
      transition: all 0.3s ease;
      right: -250px;
      height: 100%;
      position: absolute;
    }

    .veshop-sidebar-logo img {
      height: 70px;
      width: 70px;
    }
  }

  h1 {
    color: white !important;
  }

  @media only screen and (max-width: 768px) {

    .category-box,
    .subcategory-box {
      width: calc(100vw - 64px);
    }

    .subcategory-box .b2 {
      width: calc(100% / 3);
    }
  }




    .product-box {
        width: 100%;
        height: 350px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        -ms-user-select: none;
        user-select: none;
        border: 1px solid #8A0000;
        transition: all 0.2s ease;
        padding: 0px;
    }

    @media screen and (max-width: 768px) {
        .product-box {
            height: 350px;
        }
    }

    .product-box:hover {
        border-color: #b87b7b;
    }

    

    .pb-tags {
        position: absolute;
        top: 15px;
        left: 15px;
    }

    .pb-counter {
        position: absolute;
        top: 15px;
        right: 20px;
        font-family: "Bricolage Grotesque",
            BlinkMacSystemFont,
            -apple-system,
            "Segoe UI",
            "Roboto",
            "Oxygen",
            "Ubuntu",
            "Cantarell",
            "Fira Sans",
            "Droid Sans",
            "Helvetica Neue",
            "Helvetica",
            "Arial",
            sans-serif;
        text-align: center;
        background-color: rgba(104, 18, 18, 0.349);
        -webkit-box-shadow: 0px 0px 12px 15px rgb(104, 18, 18, 0.349);
        box-shadow: 0px 0px 12px 15px rgb(104, 18, 1, 0.349);
        border-radius: 50px;
    }

    .product-box:hover p {
        text-decoration: underline;
    }

  .tp1 {
    padding: 20px;
    background-color: #8A0000;
    cursor: pointer;
  }


  .tp1-subtitle {
    font-size: 2rem;
    color: white;
    font-style: italic;
    font-family: "Roboto",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
  }

  .tp1-title {
    font-family: "Bricolage Grotesque",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    font-weight: bold;
    font-size: 6rem;
    color: #8A0000;
    line-height: 6rem;
  }

  .tp1-price {
    position: absolute;
    right: 35px;
    color: white;
    font-family: "Bricolage Grotesque",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    font-size: 2rem;
    bottom: 80px;
  }

  .tp2-price * {
    padding: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    height: auto;
    font-family: "Bricolage Grotesque",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    font-size: 1.5rem;
  }

  .tp2-title {
    font-family: "Bricolage Grotesque",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    font-weight: 500;
    font-size: 4rem;
    color: white;
    line-height: 6rem;
  }

  .tp2-subtitle {
    font-size: 1.5rem;
    color: white;
    font-style: italic;
    font-family: "Roboto",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;
    margin-bottom: 0.5rem;
  }

  .tp2-image {
    height: auto;
    width: auto;
    display: block;
    max-width: 300px;
    margin: auto;
    max-height: 300px;
  }


  .tp1 .columns,
  .tp2 .columns,
  .tp1,
  .tp2 {
    height: 400px;
    min-height: 400px;
    max-height: 400px;
  }

  .blg-leader {
    overflow: hidden;
    width: 100%;
    position: relative;
    height: 300px;
    background-color: #8A0000;
    padding: 50px;
  }

  .blgl-inner {
    width: 100%;
    height: calc(300px - 100px);
    background-image: url(./assets/dots.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    text-align: center;
    padding: 30px;
  }

  .blgl-inner::after {
    max-width: 300px;
  }

  .blgli-title {
    font-family: "Roboto",
      BlinkMacSystemFont,
      -apple-system,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      sans-serif;

    font-size: 2.25rem;
    font-weight: bold;
    margin-bottom: -5px;
  }

  .content h1,
  .content h2,
  .content h3,
  .content h4,
  .content h5,
  .content h6 {
    color: white !important;
  }

  .blgli-meta {
    font-size: 0.9rem;

  }

  .blgli-button {
    margin-top: 10px;
  }

  .blg-image {
    height: 100%;
    width: auto;
    position: absolute;
    opacity: 0.2;
    top: 0;
    overflow: hidden;
    right: 0;
    -webkit-mask-image: -webkit-gradient(linear, top left, bottom left, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    mask-image: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  }

  .black-container {
    background-color: #00000059;
    padding-top: 36px;
    min-height: 60vh;
  }

  .dialog {
    z-index: 99999;

    .modal-card {
      max-width: unset;
    }

    .modal-card-body {
      background-color: black;
    }

    .modal-card-title {
      color: white;
    }
  }
</style>

<script>
  import Footer from '@/components/Footer.vue';
  import Topbar from '@/components/Topbar.vue';
  import axios from "axios";
  //import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
  import {
    SnackbarProgrammatic as Snackbar
  } from 'buefy';
  var base64 = require('base-64');
  var utf8 = require('utf8');
  //import Footer from '@/components/Footer.vue';
  const DEFAULT_TRANSITION = 'fade';
  export default {
    name: 'veShop',
    title: 'Kovářství Buchar',
    components: {
      Footer,
      Topbar,
      // VueCookieAcceptDecline
      /**Footer*/

    },

    data() {
      return {
        cookieStatus: null,
        expanded: true,
        blanking: true,
        transitionName: DEFAULT_TRANSITION,
        cart: {
          hasItems: false,
          items: 0,
          total: 0,
          totalClean: 0
        },

      };
    },
    mounted() {
      this.createCart();
      this.addVisit();
      this.getCart();
    },
    created() {
      this.$gtag.screenview({
        app_name: 'kovarstvibuchar.cz',
        screen_name: 'Home page',
      });
      this.$gtag.set({
        'currency': 'CZK',
        'country': 'CZ'
      });
      this.$Progress.start();
      this.$router.beforeEach((to, from, next) => {
        if (to.meta.progress !== undefined) {
          let meta = to.meta.progress;
          this.$Progress.parseMeta(meta);
          this.$gtag.pageview(to);
        }
        this.$Progress.start();
        this.expanded = true;
        next();
      });

      document.addEventListener('scroll', () => {
        document.documentElement.dataset.scroll = window.scrollY;
      });

    },
    methods: {

      getCart() {
        const hostname = "https://kovarstvibuchar.cz/fujin3/api/rest/rest_call.php";
        var data = new FormData();
        data.append('bin', '/apps/persephone/cgi/cart');
        data.append('stdin', 'get_cart_topbar(' + this.$cookies.get("persephone_cart") + ')');
        axios.post(hostname, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then((response) => {
            this.products = response.data.data;
            if (response.data.data.status == "OK") {
              this.cart.hasItems = response.data.data.topbar.hasItems;
              this.cart.items = response.data.data.topbar.items;
              this.cart.total = response.data.data.topbar.total;
              this.cart.totalClean = response.data.data.topbar.totalClean;
            } else {
              console.log(response);

              Snackbar.open({
                duration: 5000,
                message: "Při načítání košíku se stala nehoda, prosím aktualizujte stránku. ",
                type: 'is-danger',
                position: 'is-bottom-left',
                actionText: 'Undo',
                queue: false,
              });
            }
          })
          .catch(function (error) {
            Snackbar.open({
              duration: 5000,
              message: error,
              type: 'is-danger',
              position: 'is-bottom-left',
              actionText: 'Undo',
              queue: false,
            });
          });
      },
      getCookie(name) {
        var dc = document.cookie;
        var prefix = name + "=";
        var begin = dc.indexOf("; " + prefix);
        if (begin == -1) {
          begin = dc.indexOf(prefix);
          if (begin != 0) return null;
        } else {
          begin += 2;
          var end = document.cookie.indexOf(";", begin);
          if (end == -1) {
            end = dc.length;
          }
        }
        // because unescape has been deprecated, replaced with decodeURI
        //return unescape(dc.substring(begin + prefix.length, end));
        return decodeURI(dc.substring(begin + prefix.length, end));
      },
      addVisit() {
        var myCookie = this.getCookie("Visited");
        if (myCookie == null) {
          this.setCookie("Visited", true, 365);
          const hostname = "https://kovarstvibuchar.cz/fujin3/api/rest/rest_call.php";
          var data = new FormData();
          data.append('bin', '/apps/persephone/cgi/mainpage');
          data.append('stdin', 'add_visit(1)');
          axios.post(hostname, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          // .then((response) => {});
        }
      },
      setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
      },
      addToCart(product, amount, variant, gifts, to_gravier = {}, price = "") {
        if (this.$cookies.get("persephone_cart") == undefined || this.$cookies.get("persephone_cart") == "") {
          this.createCart();
        }
        this.$gtag.event("add_to_cart", {
          'event_category': "ecommerce",
          'event_label': "add_to_cart",
          'value': product
        })
        var utfgravier = utf8.encode(JSON.stringify(to_gravier));
        const hostname = "https://kovarstvibuchar.cz/fujin3/api/rest/rest_call.php";
        var data = new FormData();
        data.append('bin', '/apps/persephone/cgi/cart');
        data.append('stdin', 'add_to_cart(' + product + ',' + amount + ',' + variant + ',' + this.$cookies.get(
          "persephone_cart") + ',' + gifts + ',' + base64.encode(utfgravier) + ',' + price + ')');
        axios.post(hostname, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then((response) => {
            this.products = response.data.data;
            if (response.data.data.status == "OK") {
              this.cart.hasItems = response.data.data.topbar.hasItems;
              window.fbq('track', 'AddToCart', response.data.data.pixel);
              this.cart.items = response.data.data.topbar.items;
              this.cart.total = response.data.data.topbar.total;
              Snackbar.open({
                duration: 7000,
                message: "Produkt přidán do košíku. Právě máte <b>" + this.cart.items + " produktů</b> v košíku.",
                size: "is-medium",
                pauseOnHover: true,
                type: 'is-primary',
                position: 'is-top',
                actionText: 'Do košíku',
                onAction: () => {
                  this.$router.push('/kosik')
                },
                queue: false
              });
            } else {
              console.log(response);

              Snackbar.open({
                duration: 5000,
                message: "Při tvorbě košíku se stala nehoda, prosím aktualizujte stránku. ",
                type: 'is-danger',
                position: 'is-bottom-left',
                actionText: 'Undo',
                queue: false,
              });
            }
          })
          .catch(function (error) {
            Snackbar.open({
              duration: 5000,
              message: error,
              type: 'is-danger',
              position: 'is-bottom-left',
              actionText: 'Undo',
              queue: false,
            });
          });
      },
      createCart() {
        if (this.$cookies.get("persephone_cart") == undefined || this.$cookies.get("persephone_cart") == "" || this
          .$cookies.get("persephone_cart") == null) {
          const hostname = "https://kovarstvibuchar.cz/fujin3/api/rest/rest_call.php";
          var data = new FormData();
          data.append('bin', '/apps/persephone/cgi/cart');
          data.append('stdin', 'new_cart(1)');
          axios.post(hostname, data, {
              headers: {
                'Content-Type': 'application/json'
              }
            })
            .then((response) => {
              this.products = response.data.data;
              if (response.data.data.status == "OK") {
                this.$cookies.set('persephone_cart', response.data.data.cart_id);
                console.log("Welcome to Persephone veShop! Your cart identification is " + this.$cookies.get(
                  "persephone_cart"));
              } else {
                console.log(response);

                Snackbar.open({
                  duration: 5000,
                  message: "Při tvorbě košíku se stala nehoda, prosím aktualizujte stránku. " + response.data
                    .message,
                  type: 'is-danger',
                  position: 'is-bottom-left',
                  actionText: 'Undo',
                  queue: false,
                });
              }
            })
            .catch(function (error) {
              Snackbar.open({
                duration: 5000,
                message: error,
                type: 'is-danger',
                position: 'is-bottom-left',
                actionText: 'Undo',
                queue: false,
              });
            });
        }
      }
    },
    metaInfo() {
      return {
        title: "Kovářství Buchar",
        meta: [{
            name: 'description',
            content: 'Ručně vyráběné Kovářství Buchar, přírodní i stylově opálené.'
          },
          {
            property: 'og:title',
            content: "Kovářství Buchar"
          },
          {
            property: 'og:site_name',
            content: 'Kovářství Buchar'
          },
          {
            property: 'og:type',
            content: 'website'
          },
          {
            name: 'robots',
            content: 'index,follow'
          }
        ]
      }
    },
    meta: [{
        charset: 'utf-8'
      },
      {
        equiv: 'Content-Type',
        content: 'text/html'
      },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1'
      }
    ]
  }
</script>