<template>
    <div v-if="loaded">
        <b-carousel animated="fade" v-if="category.parent == 0" v-model="categoryImg" :interval="6500">
            <b-carousel-item @click="isImageModalActive = true" v-for="(item, i) in category.image.split(',')" :key="i">
                <b-image class="image"  ratio="5by2" :src="item" lazy></b-image>
            </b-carousel-item>
        </b-carousel>
        <portal to="destination"  v-if="category.parent == 0">
            <b-carousel animated="fade" :indicator="false" v-model="categoryImg" :interval="6500">
                <b-carousel-item v-for="(item, i) in category.image.split(',')" :key="i">
                    <b-image class="full-image" :src="item" lazy></b-image>
                </b-carousel-item>
            </b-carousel>
        </portal>
        <b-modal v-model="isImageModalActive">
            <b-carousel v-if="category.parent == 0 && isImageModalActive" v-model="categoryImg" :interval="6500">
                <b-carousel-item v-for="(item, i) in category.image.split(',')" :key="i">
                    <b-image responsive ratio="3by2" :src="item" lazy></b-image>
                </b-carousel-item>
            </b-carousel>
        </b-modal>
        <div class="black-container">
            <div class="container is-fluid">
                <b-breadcrumb size="is-small">
                <b-breadcrumb-item tag='router-link' to="/">{{ eshop.name }}</b-breadcrumb-item>
                <b-breadcrumb-item tag='router-link' to="/kategorie">Kategorie</b-breadcrumb-item>
                <b-breadcrumb-item tag='router-link' :to="category.slug" active>{{ category.name }}</b-breadcrumb-item>
            </b-breadcrumb>

            <div class="columns is-center is-mobile  category-head">
                <div class="column is-narrow">
                    <h1 class="title has-text-white">{{ category.name }}</h1>
                </div>
                <div class="column">
                    <div class="texture"></div>
                </div>
            </div>
            <div class="content" v-html="category.description">
                <b-skeleton></b-skeleton>
            </div>
            <div class="columns is-center is-mobile is-multiline" v-if="false">
                <div class="column is-narrow" v-for="subcategory in category.subcategories.data" :key="subcategory.slug">
                    <router-link class="subcategory-box" tag="button" :to="subcategory.slug">
                        <img :src="subcategory.image" alt="subcategory.name">
                        <p class="name">{{ subcategory.name }}</p>
                        <div class="counter">

                            <div class="count">{{ subcategory.productsNo }}</div>
                            <div class="countTag">{{ subcategory.productsNo > 2 ? (subcategory.productsNo > 4 ? "PRODUKTŮ" :
                                "PRODUKTY") : "PRODUKT" }}</div>
                        </div>
                    </router-link>

                </div>
            </div>
            <Products v-if="false" :categoryId="categoryId" :subcategoriesIds="subcategoriesIds" />
            <br>
            </div>
        </div>
    </div>
</template>

<script>
    import Products from '@/components/Products.vue';

    import axios from "axios";
    export default {
        name: 'veShopCategoryView',
        components: {
            Products
        },
        props: {
            technical: Object
        },
        title: "Kovářství Buchar",
        data() {
            return {
                loaded: false,
                categoryImg: 0,
                categoryId: 0,
                subcategoriesIds: [],
                eshop: {
                    name: "Kovářství Buchar"
                },
                isImageModalActive: false,
                category: {}
            }
        },
        methods: {
            getImgUrl(value) {
                return `https://picsum.photos/id/43${value}/1230/1200`
            },
            async loadCategory(category) {
                var data = new FormData();
                data.append('bin', '/apps/persephone/cgi/categories');
                data.append('stdin', 'get_category(' + category + ')');
                const hostname = "https://kovarstvibuchar.cz/fujin3/api/rest/rest_call.php";
                axios.post(hostname, data, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then((response) => {
                        this.category = response.data.data;
                        this.categoryId = response.data.data.ID;
                        this.subcategoriesIds = response.data.data.subcategories.ids;
                        this.loaded = true;

                        this.$Progress.finish();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
        created() {
            this.loaded = false;
            this.loadCategory(this.$props.technical.technical);
        },
        watch: {
            technical: function (newVal) {
                this.loaded = false;
                this.loadCategory(newVal.technical);
            }
        }
    }
</script>
<style scoped lang="scss">
    .image {
        height: 450px;
    }

    .full-image {
        height: 100vh;
        filter: blur(20px) brightness(50%);

        --mask: linear-gradient(to bottom,
                rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) 40%,
                rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0) 0) 100% 50% / 100% 100% repeat-x;

        -webkit-mask: var(--mask);
        mask: var(--mask);
    }

    .texture {
        background-image: url('../assets/texture.svg');
        height: 40px;
        background-position: center;
    }
</style>