<template>
    <footer>
        <div class="claims" v-if="$route.name != 'Domov'">
            <router-link tag="div" to="/kategorie/kovarske-prace" class="kovarske-prace">
                <div class="claim-content">
                    <p class="main">{{ $ml.get('KOVARSKE_PRACE')}}</p>
                    <p class="sub">{{ $ml.get('UMELECKE_A_ZEMEDELSKE') }}</p>
                </div>
            </router-link>
            <router-link tag="div" to="/kategorie/zamecnicke-prace" class="zamecnicke-prace">
                <div class="claim-content">
                    <p class="main">{{ $ml.get('ZAMECNICKE_PRACE') }}</p>
                    <p class="sub">{{ $ml.get('ZAMECNICKE_A_NEREZOVE') }}</p>
                </div>
            </router-link>
            <router-link tag="div" to="/kategorie/svarecske-prace" class="svarecske-prace">
                <div class="claim-content">
                    <p class="main">{{ $ml.get('SVARECSKE_PRACE') }}</p>
                    <p class="sub">{{ $ml.get('SVAROVANI_A_LETOVANI') }}</p>
                </div>
            </router-link>
        </div>
        <div class="ve-footer">
            <div class="back-logo is-hidden-tablet-only is-hidden-desktop-only is-hidden-mobile"></div>
            <div class="menu-item" v-for="(menuitem, m) in menu" :key="m">
                <p class="item-header">{{ $ml.get(menuitem.identifier) }}</p>
                <ul>
                    <router-link tag="li" :to="link.link" v-for="(link,l) in menuitem.children" :key="l">
                        {{ $ml.get(link.identifier) }}
                    </router-link>
                </ul>
            </div>
            <div class="menu-item">
                <p class="item-header">{{ $ml.get('ZUSTANTE_V_KONTAKTU') }}</p>
                <router-link to="/facebook"><b-icon icon="facebook" type="is-white"></b-icon></router-link>
                <router-link to="/instagram"><b-icon icon="instagram" type="is-white"></b-icon></router-link>
            </div>
            <div class="spacer is-hidden-tablet-only is-hidden-desktop-only is-hidden-widescreen-only"></div>
            <div class="menu-end">
                <p class="phone-number">734 304 528</p>
                <p class="phone-number">739 029 320</p>
                <div class="email-address"><a href="mailto:info@kovarstvibuchar.cz">info@kovarstvibuchar.cz</a></div>
            </div>
        </div>
        <div class="foot">
            <div>
                © {{ $ml.get('KOVARSTVI_A_ZAMECNICTVI_BUCHAR') }} 2020 - {{ new Date().getFullYear()  }} <b class="is-hidden-desktop-only is-hidden-tablet-only is-hidden-mobile">Website by Vespotok, proudly running on OpenFujin</b>
            </div>
            <div class="languages">
                <div class="infotext">
                    Change the language here...
                </div>
                <div class="venavtw-language clickable" v-for="language, i in languages" :key="i" @click="language_change(language.identifier)">
                    <img :src="language.icon" :alt="language.identifier">
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
    export default {
        name: 'veShopFooter',
        components: {},
        methods: {
            language_change: function (identifier) {
                this.$ml.change(identifier)
            }
        },
        data() {
            return {
                infos: {
                    name: "Kovářství Buchar",
                    established: "2020"
                },
                languages: [
                    {
                        identifier: "czech",
                        icon: "https://kovarstvibuchar.cz/assets/img/langs/cs.svg"
                    },
                    {
                        identifier: "german",
                        icon: "https://kovarstvibuchar.cz/assets/img/langs/de.svg"
                    }, {
                        identifier: "english",
                        icon: "https://kovarstvibuchar.cz/assets/img/langs/en.svg"
                    },
                    {
                        identifier: "latin",
                        icon: "https://kovarstvibuchar.cz/assets/img/langs/la.svg"
                    }
                ],
                menu: [
                    {
                        identifier: 'O_NAKUPU',
                        children: [
                            {
                                identifier: 'OBCHODNI_PODMINKY',
                                link: '/obchodni-podminky'
                            },
                            {
                                identifier: 'GDPR',
                                link: '/GDPR'
                            },
                          /*  {
                                identifier: 'E_SHOP',
                                link: '/kategorie'
                            }*/
                        ]
                    },
                    {
                        identifier: 'O_NAS',
                        children: [
                            {
                                identifier: 'KONTAKT',
                                link: '/kontakt'
                            },
                            {
                                identifier: 'NAS_PRIBEH',
                                link: '/o-nas'
                            }
                        ]
                    },
                    {
                        identifier: 'SLUZBY',
                        children: [
                            {
                                identifier: 'KOVARSKE_PRACE',
                                link: '/kategorie/kovarske-prace'
                            },
                            {
                                identifier: 'ZAMECNICKE_PRACE',
                                link: '/kategorie/zamecnicke-prace'
                            },
                            {
                                identifier: 'SVARECSKE_PRACE',
                                link: '/kategorie/svarecske-prace'
                            }
                        ]
                    }
                ],
                xty: 50
            }
        },
        async mounted() {
            //var data = new FormData();
            //data.append('bin', '/apps/persephone/cgi/mainpage');
            //data.append('stdin', 'get_visits(1)');
            //const hostname = "https://kovarstvibuchar.cz/fujin3/api/rest/rest_call.php";
            //axios.post(hostname, data, {
            //        headers: {
            //            'Content-Type': 'application/json'
            //        }
            //    })
            //    .then((response) => {
            //        console.log(response);
            //        this.xty = response.data.data.sumvisits
            //    });
        }


    }
</script>
<style scoped lang="less">
    .foot {
        display: flex;
        flex-wrap: wrap;
        padding: 16px;
        background-color: #2C0900;
        color: #7D0000;
        justify-content: space-between;
        .languages {
            display: flex;
            gap: 16px;
            .venavtw-language {
                width: 18px;
                height: 18px;
            }
        }
    }
    .claims {
        display: flex;
        justify-content: space-around;
        background-color: #2C0900;
        padding: 24px;
        gap: 24px;
        flex-wrap: wrap;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
        .main {
            font-size: 1.125rem;
            font-weight: bold;
            line-height: 1.125rem;
        }
        .kovarske-prace
        {
            display: flex;
            gap: 12px;
            align-items: center;
            &::before {
                content: " ";
                display: inline-block;
                background-image: url('../assets/kovarskeprace.png');
                width: 50px;
                height: 50px;
            }
        }
        .zamecnicke-prace
        {
            display: flex;
            gap: 12px;
            align-items: center;
            &::before {
                content: " ";
                display: inline-block;
                background-image: url('../assets/zamecnickeprace.png');
                width: 50px;
                height: 50px;
            }
        }
        .svarecske-prace
        {
            display: flex;
            gap: 12px;
            align-items: center;
            &::before {
                content: " ";
                display: inline-block;
                background-image: url('../assets/svarecskeprace.png');
                width: 50px;
                height: 50px;
            }
        }
    }
    .ve-footer {
        width: 100%;
        height: 100%;
        display: flex;
        gap: 64px;
        @media screen and (max-width: 635px) {
            gap: 24px
        }
        flex-wrap: wrap;
        padding: 24px;
        background-color: #27120D;
        .back-logo {
            background-image: url('../assets/footerlogo.png');
            width:175px;
            height: 165px;
            margin: -24px;
            background-size: cover;
        }
        
        .spacer 
        {
            flex-grow: 1;
        }
        .menu-end {
            text-align: right;
            .phone-number {
                font-size: 2.125rem;
                font-weight: bold;
                color: #7D0000;
                margin-bottom: -8px;
            }
            .email-address {
                font-size: 1.125rem;
                font-weight: bold;
                a {
                    color: #7D0000;
                }
            }
        }
        .menu-item {
            .item-header {
                font-size: 1.125rem;
                color: #7D0000;
                font-weight: bold;
                margin-bottom: 12px;
            }
            li {
                cursor: pointer;
                &::before {
                    content: ">";
                    color: #7D0000;
                    font-weight: bold;
                    display: inline-block;
                    width: 1em;
                }
            }
        }
    }
</style>