<template>
    <div class="main-page">
        <section class="main-section">
            <div class="text-inside title">{{ $ml.get('KOVARSTVI_A_ZAMECNICTVI_BUCHAR') }}</div>
        </section>
        <div class="claims">
            <router-link tag="div" to="/kategorie/kovarske-prace" class="kovarske-prace">
                    <div class="claim-content">
                        <p class="main">{{ $ml.get('KOVARSKE_PRACE') }}</p>
                        <p class="sub">{{ $ml.get('UMELECKE_A_ZEMEDELSKE') }}</p>
                    </div>
                </router-link>
                <router-link tag="div" to="/kategorie/zamecnicke-prace" class="zamecnicke-prace">
                    <div class="claim-content">
                        <p class="main">{{ $ml.get('ZAMECNICKE_PRACE') }}</p>
                        <p class="sub">{{ $ml.get('ZAMECNICKE_A_NEREZOVE') }}</p>
                    </div>
                </router-link>
                <router-link tag="div" to="/kategorie/svarecske-prace" class="svarecske-prace">
                    <div class="claim-content">
                        <p class="main">{{ $ml.get('SVARECSKE_PRACE') }}</p>
                        <p class="sub">{{ $ml.get('SVAROVANI_A_LETOVANI') }}</p>
                    </div>
                </router-link>
        </div>
        <div class="black-container">
            
        </div>

    </div>
</template>
<style>
    .category_text {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 1.2rem;
        color: white;
        font-weight: bold;
    }

    .category_a {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        background-position: center;
        background-size: cover;
        position: relative;
        cursor: pointer;
        transition: all 0.4s ease;
    }

    .category_b {
        width: 100%;
        height: 180px;
        border-radius: 15px;
        background-position: center;
        background-size: cover;
        position: relative;
        cursor: pointer;
        transition: all 0.8s ease;
    }

    .category_a:hover,
    .category_b:hover {
        filter: grayscale(100%) opacity(80%);
    }

    .top0 {
        width: 70px;
    }

    .top1 {
        font-weight: bold;

    }

    .top2 {
        font-size: .8rem;

    }
    .claims {
        display: flex;
        justify-content: space-around;
        background-color: #2C0900;
        padding: 24px;
        gap: 24px;
        flex-wrap: wrap;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
        .main {
            font-size: 1.125rem;
            font-weight: bold;
            line-height: 1.125rem;
        }
        .kovarske-prace
        {
            display: flex;
            gap: 12px;
            align-items: center;
            &::before {
                content: " ";
                display: inline-block;
                background-image: url('../assets/kovarskeprace.png');
                width: 50px;
                height: 50px;
            }
        }
        .zamecnicke-prace
        {
            display: flex;
            gap: 12px;
            align-items: center;
            &::before {
                content: " ";
                display: inline-block;
                background-image: url('../assets/zamecnickeprace.png');
                width: 50px;
                height: 50px;
            }
        }
        .svarecske-prace
        {
            display: flex;
            gap: 12px;
            align-items: center;
            &::before {
                content: " ";
                display: inline-block;
                background-image: url('../assets/svarecskeprace.png');
                width: 50px;
                height: 50px;
            }
        }
    }
    .main-section {
        width: 100%;
        height: 300px;
        background-image: url("https://kovarstvibuchar.cz/assets/img/gallery/gallery_25.jpg");
        background-position: center;
        background-size: cover;
        position: relative;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
    }

    .tagline {
        text-align: center;
        font-size: 5.8rem;
        line-height: 1.8rem;
        font-weight: 600;
    }

    .text-inside {

        color: #d60000;
        width: fit-content;
        font-weight: 900;
        height: fit-content;
        font-size: 5rem;
        padding: 10px 20px;
    }

    small {
        line-height: 0.875em !important;
    }

    @media screen and (max-width: 768px) {

        .text-inside {

            width: calc(100% - 100px);
            left: 0;
            top: 0;
            text-align: center;
            margin: 50px;
            font-weight: 900;
            font-size: 1.5rem;
        }

        .main-section {
            margin-bottom: 30px;
        }

        .text-outside {
            width: calc(100% - 50px);

            padding: 20px;
            border-radius: 15px;
            position: absolute;
            bottom: -100px;
            right: 0;
            margin: 25px;
            z-index: 20;
            font-size: 0.9rem;
        }
    }
</style>
<script>
    export default {
        name: 'veShopTopbar',
        title: "Kovářství Buchar",
        components: {
            
        },
        data() {
            return {}
        },
        computed: {

        },
        methods: {

        },
        mounted() {
            this.$Progress.finish();
        }


    }
</script>