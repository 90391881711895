<template>
    <div>
        <div class="veshop-topbar">
            <nav class="menu">
                <div class="search-el" v-if="false">
                    <div class="search-icon"></div>
                    <input placeholder="Kovaná růže..." @input="doSearch" v-model="searched"
                        class="search-box-t is-hidden-mobile is-hidden-tablet-only">
                </div>
                <div class="menu-center">
                    <router-link tag="div" to="/o-nas" class="menu-link is-hidden-mobile">{{ $ml.get('O_NAS') }}</router-link>
                    <router-link tag="div" to="/kontakt" class="menu-link is-hidden-mobile">{{ $ml.get('KONTAKT') }}</router-link>
                    <router-link class="menu-logo" tag="div" to="/"></router-link>
                    <b-dropdown aria-role="list">
                        <template #trigger>
                            <div class="menu-link is-hidden-mobile" role="button">{{ $ml.get('NASE_SLUZBY') }}</div>
                        </template>


                        <b-dropdown-item aria-role="listitem" @click="$router.push('/kategorie/kovarske-prace')">
                            {{ $ml.get('KOVARSKE_PRACE') }}</b-dropdown-item>
                        <b-dropdown-item aria-role="listitem" @click="$router.push('/kategorie/zamecnicke-prace')">
                            {{ $ml.get('ZAMECNICKE_PRACE') }}</b-dropdown-item>
                        <b-dropdown-item aria-role="listitem" @click="$router.push('/kategorie/svarecske-prace')">
                            {{ $ml.get('SVARECSKE_PRACE') }}</b-dropdown-item>
                    </b-dropdown>

                    <router-link tag="div" to="/galerie" class="menu-link is-hidden-mobile">{{ $ml.get('GALERIE') }}</router-link>
                </div>
                <div class="cart-el-wrapper" v-if="false">
                    <router-link tag="div" class="cart-el" to="/kosik">
                        <div class="cart-icon"></div>
                        <div class="cart-info is-hidden-tablet-only is-hidden-mobile">
                            <transition name="fade">
                                <span v-if="$parent.cart.hasItems">
                                    {{ $parent.cart.items }}
                                    <span v-if="$parent.cart.items == 1">věc</span>
                                    <span v-if="$parent.cart.items >= 2 && $parent.cart.items <= 4">věci</span>
                                    <span v-if="$parent.cart.items >= 5">věcí</span>
                                    &nbsp;
                                    <small>({{ $parent.cart.total }})</small></span>
                                <span v-else>Váš košík</span>
                            </transition>
                        </div>
                    </router-link>
                </div>

                <transition name="fade">
                    <div class="search-box" v-if="blanking">
                        <div class="no-results" v-if="search_results.number == 0"></div>
                        <div class="columns is-multiline" v-if="search_results.number != 0">
                            <div class="column is-one-fifth" v-for="category in search_results.categories"
                                :key="category.slug" @click="searched = ''" :style="category.delay">

                                <router-link class="category-box" style="width:100%" tag="button" :to="category.slug">
                                    <p class="name">{{ category.name }}</p>

                                </router-link>


                            </div>
                        </div>
                        <div class="columns is-multiline">
                            <div class="column is-one-quarter is-full-mobile" v-for="product in search_results.products"
                                :key="product.slug">
                                <div class="product-box fade-in-bottom" :style="product.delay">
                                    <router-link tag="div" :to="product.link" class="product-image"
                                        :id="'image' + product.ID" :style="product.background">
                                    </router-link>
                                    <div class="pb-tags">
                                        <b-field>
                                            <b-tag type="is-warning is-light" v-if="product.freeShipping" icon="truck"
                                                icon-size="16">Doprava zdarma</b-tag>
                                        </b-field>
                                        <b-field>
                                            <b-tag type="is-danger is-light" v-if="product.gift" icon="gift" size="16">
                                                Dárek!
                                            </b-tag>
                                        </b-field>
                                        <b-field>
                                            <b-tag type="is-danger" v-if="product.sale > 0"><b>-{{ product.sale }}%
                                                    sleva</b>
                                            </b-tag>
                                        </b-field>
                                    </div>
                                    <div class="pb-counter">
                                        <div class="count" v-if="product.inStock > 0">{{ product.inStock }}</div>
                                        <div class="count has-text-danger" v-if="product.inStock == 0">NENÍ</div>
                                        <div class="count" v-if="product.inStock == '*'">JE</div>
                                        <div class="countTag">SKLADEM</div>
                                    </div>


                                    <div class="pb-name-buttons">
                                        <router-link tag="p" :to="product.link">{{ product.name }}</router-link>
                                        <div class="buttons-custom">
                                            <b-button size="is-meidu" type="is-primary is-light" icon-left="cart"
                                                :data-product="product.ID" :id="'button' + product.ID"
                                                @click="addToCart(product.ID, 0)">
                                                <b>{{ product.price }} {{ product.currency }}</b>
                                            </b-button>
                                            <b-button size="is-meidu" class="is-hidden" type="is-light"
                                                icon-right="heart" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </nav>
        </div>

        <transition name="fade">
            <div class="blanket" v-if="blanking" @click="searched = ''"></div>
        </transition>

    </div>
</template>

<script>
    //<div class="column is-narrow is-hidden-desktop">
    //                        <b-button size="is-large" @click="updateExpanded()" icon-right="menu"
    //                            class="is-ghost topbar-button-tablet" />
    //                    </div>
    import axios from "axios";
    import debounce from 'lodash/debounce';

    export default {
        name: 'veShopTopbar',
        props: ['cart'],
        data() {
            return {
                searched: "",
                search_results: {
                    categories: [],
                    products: [],
                    number: 0
                }
            }
        },
        computed: {
            blanking: function () {
                if (this.searched != "")
                    return true;
                else
                    return false;
            }
        },
        methods: {
            updateExpanded: function () {
                this.$parent.expanded = !this.$parent.expanded
            },
            addToCart(productId, gifts) {
                document.getElementById("button" + productId).classList.add("is-loading");
                this.$parent.addToCart(productId, 1, 0, gifts);

                setTimeout(function () {
                    document.getElementById("button" + productId).classList.remove("is-loading");
                }, 800);
            },
            doSearch: debounce(function () {
                var data = new FormData();
                this.$gtag.event("search", {
                    'event_category': "engangement",
                    'event_label': "search_term",
                    'value': this.searched
                })
                data.append('bin', '/apps/persephone/cgi/search');
                data.append('stdin', 'search("' + this.searched + '")');
                const hostname = "https://kovarstvibuchar.cz/fujin3/api/rest/rest_call.php";
                axios.post(hostname, data, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then((response) => {
                        this.search_results = response.data.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }, 500)
        },
    }
</script>

<style scoped lang="less">
    .veshop-topbar {
        position: fixed;
        width: 100%;
        border-bottom: 1px solid transparent;
        z-index: 999;
        transition: all .2s ease-in-out;

        nav.menu {
            display: flex;
            //justify-content: space-between;
            justify-content: center;
            align-items: center;
            padding: 12px 24px;
            max-width: 1500px;
            margin: 0 auto;
            position: relative;
            .search-el {
                width: calc(100% / 4);
                display: flex;
                gap: 24px;
                align-items: center;

                .search-box-t {
                    all: unset;
                    background: none !important;

                    &::active {
                        border-bottom: 1px solid #7D0000;
                    }
                }

                .search-icon {
                    min-width: 30px;
                    min-height: 30px;
                    background-image: url("../assets/search.png");
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    cursor: pointer;
                }
            }

            .menu-center {
                display: flex;
                gap: 36px;
                align-items: center;
                justify-content: center;
                margin-left: 32px;
                width: calc(100% / 2);

                .menu-link {
                    font-size: 1.125rem;
                    font-weight: bold;
                    cursor: pointer;
                    text-wrap: nowrap;
                    user-select: none;
                    text-overflow: ellipsis;

                    &:hover {
                        opacity: .9;
                    }
                }

                .menu-logo {
                    min-width: 65px;
                    min-height: 65px;
                    background-image: url(../assets/logo.png);
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    cursor: pointer;
                }
            }

            .cart-el-wrapper {
                width: calc(100% / 4);
                display: flex;
                flex-direction: row-reverse;

                .cart-el {
                    border: 1px solid #7D0000;

                    padding: 16px;
                    display: flex;
                    gap: 12px;
                    align-items: center;
                    cursor: pointer;
                    width: auto;
                    transition: background-color .1s ease;

                    &:hover {
                        background-color: #7d000044;
                    }

                    .cart-info {

                        font-size: 1.125rem;
                        font-weight: bold;
                    }

                    .cart-icon {
                        width: 30px;
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                        height: 30px;
                        background-image: url('../assets/cart.png');
                    }
                }

            }
        }
    }

    .blanket {
        position: absolute;
        width: 100%;
        height: 100%;
        margin-top: -2px;
        backdrop-filter: blur(5px);
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 600;
        backdrop-filter: blur(10px);
        transition: all 0.5s ease;
    }

    html:not([data-scroll='0']) .veshop-topbar {
        border-bottom: 1px solid #7D0000;
        background-color: #2C0900;
    }

    .search-box {
        width: 100%;
        max-width: calc(1500px - 48px);
        min-height: 400px;
        background-color: rgba(43, 0, 0, 0.8);
        border: 1px #8a0000 solid;
        position: fixed;
        z-index: 99999;
        padding: 15px;
        top: 89px;
    }
      .no-results {
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-image: url(../assets/no-results.svg);
    background-position: center;
    background-size: 800px;
  }

</style>