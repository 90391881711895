import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from '../views/MainPageNew.vue'
import Category1 from '../views/Category.vue'
import Category2 from '../views/Category.vue'
import AboutUs from '../views/AboutUs.vue'
//import Product from '../views/Product.vue'
//import Cart from '../views/Cart.vue'
import NotFound from '../views/NotFound.vue'
import Categories from '../views/Categories.vue'
//import OrderSent from '../views/OrderSent.vue'
import Contact from '../views/Contact.vue'
import Gallery from '../views/Gallery.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Domov',
    component: MainPage
  },
  {
    path: '/kategorie',
    name: 'Kategorie webu',
    component: Categories
  },
  {
    path: '/galerie',
    name: 'Galerie',
    component: Gallery
  },
  {
    path: '/o-nas',
    name: 'O nás',
    component: AboutUs
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: Contact
  },
  /*{
    path: '/kosik',
    name: 'Košík',
    component: Cart
  },
  {
    path: '/objednavka',
    name: 'Objednávka',
    component: OrderSent
  },*/
  {
    path: '/kategorie/:technical',
    name: 'Kategorie',
    component: Category1,
    props: (route) => ({
      technical: Vue.observable(new Counter(route.params.technical))
    }),
  },
  {
    path: '/kategorie/*/:technical',
    name: 'Podkategorie',
    component: Category2,
    props: (route) => ({
      technical: Vue.observable(new Counter(route.params.technical))
    }),
  },
  /*{
    path: '/produkt/*',
    name: 'Produkt',
    component: Product
  },*/
  {
    path: '/facebook',
    beforeEnter() {
      window.location.href = "https://www.facebook.com/KovarstviBuchar";
    }
  },
  {
    path: '/instagram',
    beforeEnter() {
      window.location.href = "https://www.instagram.com/kovo.buchar";
    }
  },
  {
    path: '/gdpr',
    beforeEnter() {
      window.location.href = "https://kovarstvibuchar.cz/assets/doc/gdpr.pdf";
    }
  },
  {
    path: '/obchodni-podminky',
    beforeEnter() {
      window.location.href = "https://kovarstvibuchar.cz/assets/doc/podminky.pdf";
    }
  },
  {
    path: '*',
    name: 'Not Found',
    component: NotFound
  },
  {
    path: 'undefined',
    name: 'Undefined',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

class Counter {
  constructor(value) {
    this.technical = value
  }
}


export default router