import Vue from 'vue'
import { MLInstaller, MLCreate, MLanguage } from 'vue-multilanguage'

Vue.use(MLInstaller)

export default new MLCreate({
  initial: 'czech',
  save: process.env.NODE_ENV === 'production',
  languages: [
    new MLanguage('czech').create({
        O_NAS: "O nás",
        KONTAKT: "Kontakt",
        KOSIK: "Košík",
        GALERIE: "Galerie",
        E_SHOP: "e-shop",
        GDPR: "GDPR",
        O_NAS_1: "Kovářství, zámečnictví a kovovýroba Buchar bylo založeno začátkem roku 2020 dvěma bratry, kteří se úspěšně vyučili v oboru na střední škole řemeslné v Jaroměři. Naše kovárna se nachází v malebné krajině dolních Kněžnic v Českém Ráji, kdy zhotovujeme naše kované a zámečnické výrobky.",
        O_NAS_2: "Zaměřujeme se na zakázkovou výrobu dle přání a požadavků zákazník. Ke každému zákazníkovi přistupujeme individuálně tak, aby konečný výsledek naší práce zaručil stoprocentní spokojenost. My jsme ti, kteří dávají kovu tvar a duši, ale Vaší fantazii se meze nekladou, vše s Vámi rádi probereme, naplánujeme a poradíme Vám. Jsme tu pro Vás v každém ohledu při přetváření Vaší vize do materiálové podoby.",
        O_NAS_3: "Při zhotovování zakázek dáváme pozor na kvalitní povrchovou úpravu a ošetření kovu nátěrem či práškovým lakováním, aby životnost výrobku byla co nejdelší. Pro bezúdržbovost výrobku doporučujeme žárové zinkování, které též zajišťujeme.", 
        O_NAS_4: "Po předání Vámi objednané a již zhotovené zakázky se s Vámi domluvíme na termínu montáže. Poté výrobek namontujeme na Vámi určené místo a budeme se těšit na naše příští setkání a spolupráci.",
        O_NAS_ICO: "IČO",
        O_NAS_OBCHODNI_FIRMA: "Obchodní firma",
        O_NAS_PRAVNI_FORMA: "Právní forma",
        O_NAS_DATUM_VZNIKU: "Datum vzniku",
        O_NAS_SIDLO: "Sídlo",
        O_NAS_ZUJ: "ZÚJ",
        O_NAS_OKRES: "Okres",
        CONTACT_POSITION: "Zakladatel, kovář",
        KOVARSKE_PRACE: "Kovářské práce",
        ZAMECNICKE_PRACE: "Zámečnické práce",
        SVARECSKE_PRACE: "Svářečské práce",
        O_NAKUPU: "O nákupu",
        OBCHODNI_PODMINKY: "Obchodní podmínky",
        DOPRAVA_A_PLATBA: "Doprava a platba",
        NAS_PRIBEH: "Náš příběh",
        SLUZBY: "Služby",
        UMELECKE_A_ZEMEDELSKE: "Umělecké & zemědělské",
        ZAMECNICKE_A_NEREZOVE: "Zámečnické & nerezové",
        SVAROVANI_A_LETOVANI: "Svařování & letování",
        ZUSTANTE_V_KONTAKTU: "Zůstaňte v kontaktu",
        NASE_SLUZBY: "Naše služby",
        KOVARSTVI_A_ZAMECNICTVI_BUCHAR: "Kovářství a zámečnictví Buchar"
    }),

    new MLanguage('german').create({
        O_NAS: "Über uns",
KONTAKT: "Kontakt",
KOSIK: "Warenkorb",
GALERIE: "Galerie",
E_SHOP: "E-Shop",
GDPR: "DSGVO",
O_NAS_1: "Die Schmiede, Schlosserei und Metallproduktion Buchar wurde Anfang 2020 von zwei Brüdern gegründet, die ihre Ausbildung an der Handwerksmittelschule in Jaroměř erfolgreich abgeschlossen haben. Unsere Schmiede befindet sich in der malerischen Landschaft von Dolní Kněžnice im Böhmischen Paradies, wo wir unsere geschmiedeten und schlosserischen Produkte herstellen.",
O_NAS_2: "Wir spezialisieren uns auf die maßgeschneiderte Produktion nach den Wünschen und Anforderungen der Kunden. Jeder Kunde wird individuell behandelt, um sicherzustellen, dass das Endergebnis unserer Arbeit hundertprozentige Zufriedenheit garantiert. Wir geben dem Metall Form und Seele, aber Ihrer Fantasie sind keine Grenzen gesetzt. Wir besprechen gerne alles mit Ihnen, planen und beraten Sie. Wir sind in jeder Hinsicht für Sie da, um Ihre Vision in materielle Form zu verwandeln.",
O_NAS_3: "Bei der Anfertigung von Aufträgen achten wir auf hochwertige Oberflächenbehandlung und den Schutz des Metalls durch Lackierung oder Pulverbeschichtung, um die Lebensdauer des Produkts zu maximieren. Für die Wartungsfreiheit des Produkts empfehlen wir feuerverzinken, das wir ebenfalls anbieten.",
O_NAS_4: "Nach der Fertigstellung und Übergabe Ihres bestellten Produkts vereinbaren wir mit Ihnen einen Montagetermin. Anschließend montieren wir das Produkt am von Ihnen bestimmten Ort und freuen uns auf unsere nächste Begegnung und Zusammenarbeit.",
O_NAS_ICO: "Unternehmens-Identifikationsnummer",
O_NAS_OBCHODNI_FIRMA: "Handelsfirma",
O_NAS_PRAVNI_FORMA: "Rechtsform",
O_NAS_DATUM_VZNIKU: "Gründungsdatum",
O_NAS_SIDLO: "Firmensitz",
O_NAS_ZUJ: "USt-IdNr.",
O_NAS_OKRES: "Bezirk",
CONTACT_POSITION: "Gründer, Schmied",
KOVARSKE_PRACE: "Schmiedearbeiten",
ZAMECNICKE_PRACE: "Schlosserarbeiten",
SVARECSKE_PRACE: "Schweißarbeiten",
O_NAKUPU: "Über den Kauf",
OBCHODNI_PODMINKY: "Geschäftsbedingungen",
DOPRAVA_A_PLATBA: "Versand und Zahlung",
NAS_PRIBEH: "Unsere Geschichte",
SLUZBY: "Dienstleistungen",
UMELECKE_A_ZEMEDELSKE: "Kunst & Landwirtschaft",
ZAMECNICKE_A_NEREZOVE: "Schlosser & rostfreie",
SVAROVANI_A_LETOVANI: "Schweißen & Löten",
ZUSTANTE_V_KONTAKTU: "Bleiben Sie in Kontakt",
NASE_SLUZBY: "Unsere Dienstleistungen",
KOVARSTVI_A_ZAMECNICTVI_BUCHAR: "Schmieden und Schlosserei Buchar"
    }),
    new MLanguage('english').create({
O_NAS: "About Us",
KONTAKT: "Contact",
KOSIK: "Shopping Cart",
GALERIE: "Gallery",
E_SHOP: "E-Shop",
GDPR: "GDPR",
O_NAS_1: "The blacksmithing, locksmithing, and metalworking business Buchar was founded in early 2020 by two brothers who successfully completed their apprenticeship in the trade at the vocational school in Jaroměř. Our forge is located in the picturesque landscape of Dolní Kněžnice in Český ráj, where we produce our forged and locksmith products.",
O_NAS_2: "We specialize in custom production according to the wishes and requirements of customers. We approach each customer individually to ensure that the final result of our work guarantees one hundred percent satisfaction. We are the ones who give shape and soul to metal, but there are no limits to your imagination. We are happy to discuss, plan, and advise you on everything. We are here for you in every way to transform your vision into material form.",
O_NAS_3: "When fulfilling orders, we pay attention to high-quality surface treatment and the protection of metal through painting or powder coating to maximize the product's lifespan. For maintenance-free products, we recommend hot-dip galvanizing, which we also provide.",
O_NAS_4: "After handing over your ordered and already produced product, we will arrange a date for installation with you. Then we will mount the product in the place designated by you and look forward to our next meeting and collaboration.",
O_NAS_ICO: "Business ID",
O_NAS_OBCHODNI_FIRMA: "Commercial Firm",
O_NAS_PRAVNI_FORMA: "Legal Form",
O_NAS_DATUM_VZNIKU: "Date of Establishment",
O_NAS_SIDLO: "Registered Office",
O_NAS_ZUJ: "VAT ID",
O_NAS_OKRES: "District",
CONTACT_POSITION: "Founder, Blacksmith",
KOVARSKE_PRACE: "Blacksmith Works",
ZAMECNICKE_PRACE: "Locksmith Works",
SVARECSKE_PRACE: "Welding Works",
O_NAKUPU: "About Purchase",
OBCHODNI_PODMINKY: "Business Terms",
DOPRAVA_A_PLATBA: "Delivery and Payment",
NAS_PRIBEH: "Our Story",
SLUZBY: "Services",
UMELECKE_A_ZEMEDELSKE: "Artistic & Agricultural",
ZAMECNICKE_A_NEREZOVE: "Locksmith & Stainless",
SVAROVANI_A_LETOVANI: "Welding & Brazing",
ZUSTANTE_V_KONTAKTU: "Stay in Touch",
NASE_SLUZBY: "Our Services",
KOVARSTVI_A_ZAMECNICTVI_BUCHAR: "Blacksmithing and Locksmithing Buchar"

    }),
    new MLanguage('latin').create({
        O_NAS: "De nobis",
        KONTAKT: "Contactus",
        KOSIK: "Cophinus",
        GALERIE: "Galeria",
        E_SHOP: "e-negotium",
        GDPR: "RGPD",
        O_NAS_1: "Ars fabrilis, ferramentaria, et metallifabricatio Buchar inchoata est initio anni 2020 duobus fratribus, qui bene instituti sunt in artificio in schola mechanicorum Jaroměři. Ars nostrum ferraria sita est in pictura regione inferiorum Kněžnic in Bohemico Paradyso, ubi opera nostra fabrefacta et ferramenta conficimus.",
        O_NAS_2: "Nos obstringimus ad fabricandam iuxta voluntates et postulata clientium pro confectione. Ad singulum clientem personaliter accedimus, ut opus nostrum centesimam benevolentiam promittat. Nos formamus et animamus ferrum, sed tuae imaginationi nullae sunt limites. Cum te de his omnibus libenter conferimus, consilium damus, et tecum omnia planificamus. Sumus tibi omnimodo adiutores ad tuam visionem in formam materialem convertere.",
        O_NAS_3: "In confectione operum, sollicitamus de qualitativa compositione superficiei et tractatione metalli per inunctionem vel pulvis picturae, ut longitudo vitae operis quam maxime prodigetur. Ad operationem sine cura, zinkificatio ignea commendatur, quam etiam providemus.",
        O_NAS_4: "Post traditam tibi commissionem iam confectam, statuimus cum te terminum montationis. Deinde productum montamus in loco a te destinato, et de futura nostrorum congressuum ac cooperationis expectamus.",
        O_NAS_ICO: "IDR",
        O_NAS_OBCHODNI_FIRMA: "Negotiorum nomen",
        O_NAS_PRAVNI_FORMA: "Forma iuridica",
        O_NAS_DATUM_VZNIKU: "Dies ortus",
        O_NAS_SIDLO: "Sedes",
        O_NAS_ZUJ: "ZÚJ",
        O_NAS_OKRES: "Circumscribit",
        CONTACT_POSITION: "Conditor, faber",
        KOVARSKE_PRACE: "Opera fabrilia",
        ZAMECNICKE_PRACE: "Opera ferramentaria",
        SVARECSKE_PRACE: "Opera suctoria",
        O_NAKUPU: "De emptione",
        OBCHODNI_PODMINKY: "Condicionibus commercii",
        DOPRAVA_A_PLATBA: "Transportatio et persolutio",
        NAS_PRIBEH: "Nostra fabula",
        SLUZBY: "Ministeria",
        UMELECKE_A_ZEMEDELSKE: "Artificiales & agricolae",
        ZAMECNICKE_A_NEREZOVE: "Ferramentales & inoxidabiles",
        SVAROVANI_A_LETOVANI: "Suctionales & fasciolationes",
        ZUSTANTE_V_KONTAKTU: "Manete in contactu",
        NASE_SLUZBY: "Nostra ministeria",
        KOVARSTVI_A_ZAMECNICTVI_BUCHAR: "Ars fabrilis et ferramentaria Buchar"

    })
  ]
})