<template>
    <div class="black-container">
        <div class="container is-fluid">
            <b-breadcrumb size="is-small">
                <b-breadcrumb-item tag='router-link' to="/">Kovářství Buchar</b-breadcrumb-item>
                <b-breadcrumb-item tag='router-link' to="/o-nas" active>{{ $ml.get('KONTAKT') }}</b-breadcrumb-item>
            </b-breadcrumb>

            <section class="content">

                <h1>{{ $ml.get('KONTAKT')}}</h1>
                <div class="columns is-hidden-mobile">
                    <div class="column has-text-right">
                        <br> <br>
                        <div class="is-size-4">Stanislav Buchar</div>
                        <p>{{ $ml.get('CONTACT_POSITION') }}</p>
                        <p>
                            <a href="tel:+420734304528">+420 73-430-4528</a></p>
                        <p>info@kovarstvibuchar.cz</p>
                    </div>
                    <div class="column is-narrow has-text-centered">
                        <iframe width="200" height="200" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                            src="https://www.openstreetmap.org/export/embed.html?bbox=15.322902202606203%2C50.49928661056863%2C15.324881672859194%2C50.50055594716819&amp;layer=mapnik&amp;marker=50.49992128313258%2C15.323891937732697"></iframe><br /><small><a
                                href="https://www.openstreetmap.org/?mlat=50.49992&amp;mlon=15.32389#map=19/50.49992/15.32389">{{ $ml.get('Zobrazit větší mapu') }}</a></small>
                        <address>
                            Kněžnice 2<br>
                            Jičín 506 01<br>
                            Česko
                        </address>
                    </div>
                    <div class="column has-text-left">
                        <br> <br>
                        <div class="is-size-4">Ladislav Buchar</div>
                        <p>{{ $ml.get('CONTACT_POSITION') }}</p>
                        <p>
                            <a href="tel:+420734304528">+420 73-430-4528</a></p>
                        <p>info@kovarstvibuchar.cz</p>
                    </div>
                </div>
                <div class="columns is-hidden-tablet">
                    <div class="column is-narrow has-text-centered">
                        <iframe width="200" height="200" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                            src="https://www.openstreetmap.org/export/embed.html?bbox=15.322902202606203%2C50.49928661056863%2C15.324881672859194%2C50.50055594716819&amp;layer=mapnik&amp;marker=50.49992128313258%2C15.323891937732697"></iframe><br /><small><a
                                href="https://www.openstreetmap.org/?mlat=50.49992&amp;mlon=15.32389#map=19/50.49992/15.32389">{{ $ml.get('Zobrazit větší mapu') }}</a></small>
                        <address>
                            Kněžnice 2<br>
                            Jičín 506 01<br>
                            Česko
                        </address>
                    </div>
                    <div class="column has-text-centered">

                        <div class="is-size-4">Stanislav Buchar</div>
                        <p>{{ $ml.get('CONTACT_POSITION') }}</p>
                        <p>
                            <a href="tel:+420734304528">+420 73-430-4528</a></p>
                        <p>stanislavs@kovarstvibuchar.cz</p>
                    </div>

                    <div class="column has-text-centered">
                        <br>
                        <div class="is-size-4">Ladislav Buchar</div>
                        <p>{{ $ml.get('CONTACT_POSITION') }}</p>
                        <p>
                            <a href="tel:+420734304528">+420 73-430-4528</a></p>
                        <p>ladislav@kovarstvibuchar.cz</p><br>
                    </div>
                </div>


            </section>
        </div>
    </div>
</template>
<style scoped lang="less">

</style>
<script>
    //import axios from "axios";
    export default {
        name: 'veShopTopbar',
        title: "Kovářství Buchar",
        components: {
        },
        data() {
            return {

            }
        },
        mounted() {
        this.$Progress.finish();
        }


    }
</script>